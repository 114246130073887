import React from 'react'
import PropTypes from 'prop-types'
import BgImg from 'gatsby-background-image'

const BgImage = ({ imageInfo, style, children }) => {
  const { childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <BgImg style={style} fluid={image.childImageSharp.fluid}>
        {children}
      </BgImg>
    )
  }

  if (!!childImageSharp) {
    return (
      <BgImg style={style} fluid={childImageSharp.fluid}>
        {children}
      </BgImg>
    )
  }

  if (!!image && typeof image === 'string') {
    return (
      <div style={{ ...style, backgroundImage: `url(${image})` }}>
        {children}
      </div>
    )
  }

  return null
}

BgImage.propTypes = {
  imageInfo: PropTypes.shape({
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default BgImage
