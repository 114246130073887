import React from "react";

import Image from "../components/Image";
import BgImage from "../components/BgImage";

const ParallaxImage = ({ image, children, childrenTop, childrenBottom }) => (
  <React.Fragment>
    <div className="d-block d-sm-none">
      <Image
        imageInfo={{ image: image, alt: "" }}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center center",
          fontFamily: `"object-fit: cover; object-position: center center;"`,
        }}
      />
      {children}
    </div>
    <div className="d-none d-sm-block">
      <BgImage
        imageInfo={{ image: image, alt: "" }}
        style={{
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            width: "100%",
            height: childrenTop,
          }}
        />
        {children}
        <div
          style={{
            width: "100%",
            height: childrenBottom,
          }}
        />
      </BgImage>
    </div>
  </React.Fragment>
);

export default ParallaxImage;
