import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Row } from "reactstrap";

import Layout from "../layout/Layout";
import ApartmentsRoll from "../components/ApartmentsRoll";
import RoomsRoll from "../components/RoomsRoll";

import ParallaxImage from "../components/ParallaxImage";

export const IndexPageTemplate = ({ image, title, description }) => (
  <React.Fragment>
    <ParallaxImage image={image} childrenTop="400px" childrenBottom="300px">
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <Container>
          <Row>
            <div
              className="text-center mt-5 mb-5 pl-4 pr-4"
              style={{
                width: "100%",
              }}
            >
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
          </Row>
        </Container>
      </div>
    </ParallaxImage>
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <Container>
        <Row>
          <div
            className="text-center mt-5 mb-5"
            style={{
              width: "100%",
            }}
          >
            <h1>Apartments</h1>
          </div>
          <div
            className="mb-5"
            style={{
              width: "100%",
            }}
          >
            <ApartmentsRoll />
          </div>
          <div
            className="text-center mt-5 mb-5"
            style={{
              width: "100%",
            }}
          >
            <h1>Rooms</h1>
          </div>
          <div
            className="mb-5"
            style={{
              width: "100%",
            }}
          >
            <RoomsRoll />
          </div>
        </Row>
      </Container>
    </div>
  </React.Fragment>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`;
